import { PropsWithChildren } from 'react';
import cx from 'classnames';
import { TextInput, SelectInput, selectValueAdapter } from '@intellecteu/common-ui';
import { FormikProps, getIn } from 'formik';
import { UnitAdapter, cpu, cpuMin, memory, memoryMin, storageFullMin, storageSnapMin, Labels } from 'app/constants';
import { handleFloatNumberInput } from 'app/utils/helpers';
import { MemoryEnum, CpuMetricsEnum } from 'data';
import { CustomErrorMessage, Field } from '..';

import s from './styles.module.scss';

export interface ResourcesForm {
  cpuLimit: UnitAdapter;
  cpuRequested: UnitAdapter;
  memoryLimit: UnitAdapter;
  memoryRequested: UnitAdapter;
  storageRequested: UnitAdapter;
}

type TResourcesProps = Pick<FormikProps<unknown>, 'setFieldTouched' | 'errors'> &
  PropsWithChildren & {
    startName: string;
    isBoot?: boolean;
    className?: string;
    disabled?: boolean;
  };

export const Resources = ({
  startName,
  errors,
  setFieldTouched,
  children,
  className,
  isBoot = false,
  disabled = false,
}: TResourcesProps) => {
  const onUnitChange = (v: UnitAdapter, name: string) => {
    setFieldTouched(`${startName}.${name}.value`);

    return v?.value;
  };

  return (
    <div className={cx(s.resources, className)}>
      <div className={s.resourcesForm}>
        {children}

        <div>
          <div className={s.fieldGroup}>
            <Field
              component={TextInput}
              id={`${startName}.cpuRequested.value`}
              name={`${startName}.cpuRequested.value`}
              label={`${Labels.REQUESTED_CPU}*`}
              placeholder={`Min ${cpuMin} ${CpuMetricsEnum.CPU}`}
              onKeyPress={handleFloatNumberInput}
              disabled={disabled}
            />

            <Field
              className={s.fieldGroupSelect}
              options={cpu}
              component={SelectInput}
              wrapperClassName={s.fieldGroupSelectContainer}
              id={`${startName}.cpuRequested.unit`}
              name={`${startName}.cpuRequested.unit`}
              onChangeAdapter={(v) => onUnitChange(v, 'cpuRequested')}
              valueAdapter={selectValueAdapter(cpu)}
              maxMenuHeight="200px"
              isMenuAbove
              isDisabled={disabled}
            />
          </div>

          {!getIn(errors, `${startName}.cpuRequested.value`) && (
            <CustomErrorMessage name={`${startName}.cpuRequested`} />
          )}
        </div>

        <div>
          <div className={s.fieldGroup}>
            <Field
              component={TextInput}
              id={`${startName}.cpuLimit.value`}
              name={`${startName}.cpuLimit.value`}
              placeholder={`Min ${cpuMin} ${CpuMetricsEnum.CPU}`}
              label={`${Labels.CPU_LIMIT}*`}
              onKeyPress={handleFloatNumberInput}
              disabled={disabled}
            />

            <Field
              className={s.fieldGroupSelect}
              options={cpu}
              component={SelectInput}
              wrapperClassName={s.fieldGroupSelectContainer}
              id={`${startName}.cpuLimit.unit`}
              name={`${startName}.cpuLimit.unit`}
              onChangeAdapter={(v) => onUnitChange(v, 'cpuLimit')}
              valueAdapter={selectValueAdapter(cpu)}
              maxMenuHeight="200px"
              isMenuAbove
              isDisabled={disabled}
            />
          </div>

          {!getIn(errors, `${startName}.cpuLimit.value`) && <CustomErrorMessage name={`${startName}.cpuLimit`} />}
        </div>

        <div>
          <div className={s.fieldGroup}>
            <Field
              component={TextInput}
              id={`${startName}.memoryRequested.value`}
              name={`${startName}.memoryRequested.value`}
              label={`${Labels.REQUESTED_MEMORY}*`}
              placeholder={`Min ${memoryMin} ${MemoryEnum.Gi}`}
              onKeyPress={handleFloatNumberInput}
              disabled={disabled}
            />

            <Field
              className={s.fieldGroupSelect}
              options={memory}
              component={SelectInput}
              wrapperClassName={s.fieldGroupSelectContainer}
              id={`${startName}.memoryRequested.unit`}
              name={`${startName}.memoryRequested.unit`}
              onChangeAdapter={(v) => onUnitChange(v, 'memoryRequested')}
              valueAdapter={selectValueAdapter(memory)}
              maxMenuHeight="200px"
              isMenuAbove
              isDisabled={disabled}
            />
          </div>

          {!getIn(errors, `${startName}.memoryRequested.value`) && (
            <CustomErrorMessage name={`${startName}.memoryRequested`} />
          )}
        </div>

        <div>
          <div className={s.fieldGroup}>
            <Field
              component={TextInput}
              id={`${startName}.memoryLimit.value`}
              name={`${startName}.memoryLimit.value`}
              label={`${Labels.MEMORY_LIMIT}*`}
              placeholder={`Min ${memoryMin} ${MemoryEnum.Gi}`}
              onKeyPress={handleFloatNumberInput}
              disabled={disabled}
            />

            <Field
              className={s.fieldGroupSelect}
              options={memory}
              wrapperClassName={s.fieldGroupSelectContainer}
              component={SelectInput}
              id={`${startName}.memoryLimit.unit`}
              name={`${startName}.memoryLimit.unit`}
              onChangeAdapter={(v) => onUnitChange(v, 'memoryLimit')}
              valueAdapter={selectValueAdapter(memory)}
              maxMenuHeight="200px"
              isMenuAbove
              isDisabled={disabled}
            />
          </div>

          {!getIn(errors, `${startName}.memoryLimit.value`) && <CustomErrorMessage name={`${startName}.memoryLimit`} />}
        </div>

        <div>
          <div className={s.fieldGroup}>
            <Field
              component={TextInput}
              id={`${startName}.storageRequested.value`}
              name={`${startName}.storageRequested.value`}
              label={`${Labels.STORAGE}*`}
              placeholder={`Min ${isBoot ? storageFullMin : storageSnapMin} ${MemoryEnum.Gi}`}
              onKeyPress={handleFloatNumberInput}
              disabled={disabled}
            />

            <Field
              className={s.fieldGroupSelect}
              options={memory}
              wrapperClassName={s.fieldGroupSelectContainer}
              component={SelectInput}
              id={`${startName}.storageRequested.unit`}
              name={`${startName}.storageRequested.unit`}
              onChangeAdapter={(v) => onUnitChange(v, 'storageRequested')}
              valueAdapter={selectValueAdapter(memory)}
              maxMenuHeight="200px"
              isMenuAbove
              isDisabled={disabled}
            />
          </div>

          {!getIn(errors, `${startName}.storageRequested.value`) && (
            <CustomErrorMessage name={`${startName}.storageRequested`} />
          )}
        </div>
      </div>
    </div>
  );
};
