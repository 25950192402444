/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NoSqlDatabaseDto = {
  provisionType?: NoSqlDatabaseDto.provisionType;
  dbname?: string;
  host?: string;
  port?: number | string;
  password?: string;
  storageRequest?: string;
};
export namespace NoSqlDatabaseDto {
  export enum provisionType {
    PROVISIONED = 'PROVISIONED',
    EXTERNAL = 'EXTERNAL',
  }
}
