import { SelectInput, selectValueAdapter, TextInput } from '@intellecteu/common-ui';
import { FormikErrors, FormikValues } from 'formik';
import { Labels, memory, UnitAdapter } from 'app/constants';
import { handleFloatNumberInput } from 'app/utils';
import { Field } from '../ui';

import s from './styles.module.scss';

interface StorageFieldProps {
  fieldName: string;
  min: number;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<FormikValues>>;
}

export const StorageField = ({ fieldName, min, setFieldTouched }: StorageFieldProps) => {
  const onUnitChange = (v: UnitAdapter) => {
    setFieldTouched(`${fieldName}.value`);

    return v?.value;
  };

  return (
    <div className={s.fieldGroup}>
      <Field
        component={TextInput}
        className={s.fieldGroupInput}
        id={`${fieldName}.value`}
        name={`${fieldName}.value`}
        label={`${Labels.STORAGE}*`}
        placeholder={`Min ${min}`}
        onKeyPress={handleFloatNumberInput}
      />

      <Field
        className={s.fieldGroupSelect}
        options={memory}
        wrapperClassName={s.fieldGroupSelectContainer}
        component={SelectInput}
        id={`${fieldName}.unit`}
        name={`${fieldName}.unit`}
        onChangeAdapter={(v) => onUnitChange(v)}
        valueAdapter={selectValueAdapter(memory)}
        maxMenuHeight="200px"
        isMenuAbove
      />
    </div>
  );
};
