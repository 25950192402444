/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlethioParamsDto } from './AlethioParamsDto';
import type { GenesisConfigRequestDto } from './GenesisConfigRequestDto';
import type { NumOfNodesDto } from './NumOfNodesDto';
import type { ResourcesDto } from './ResourcesDto';
import type { RpcNodeGroupDto } from './RpcNodeGroupDto';
export type NetworkRequestDTO = {
    name: string;
    numOfNodesDto: NumOfNodesDto;
    validatorResources: ResourcesDto;
    bootNodeResources: ResourcesDto;
    consensusMechanism: NetworkRequestDTO.consensusMechanism;
    genesisConfigDto: GenesisConfigRequestDto;
    besuImage?: string;
    besuImageSecret?: string;
    enableBlockExplorer: boolean;
    alethioParamsDto?: AlethioParamsDto;
    rpcNodeGroup?: RpcNodeGroupDto;
    accountsAllowListEnabled?: boolean;
    nodesAllowlistEnabled?: boolean;
};
export namespace NetworkRequestDTO {
    export enum consensusMechanism {
        QBFT = 'QBFT',
        IBFT2 = 'IBFT2',
        CLIQUE = 'CLIQUE',
    }
}
