import { TextInput, Toggle } from '@intellecteu/common-ui';
import { Labels } from 'app/constants';
import { Field } from 'app/components/core';
import { DEFAULT_NAME_VALIDATION_NOTE } from 'data/utils/validation';

export const NetworkSetupStep = () => (
  <>
    <Field
      component={TextInput}
      id="name"
      name="name"
      label={`${Labels.NETWORK_NAME}*`}
      tooltipText={DEFAULT_NAME_VALIDATION_NOTE}
      data-1p-ignore
      autoFocus
    />

    <Field component={TextInput} id="besuImage" name="besuImage" label={`${Labels.BESU_IMAGE}*`} />

    <Field component={TextInput} id="besuImageSecret" name="besuImageSecret" label={Labels.BESU_IMAGE_SECRET} />

    <Toggle.Small label={Labels.GAS_FREE_NETWORK} checked disabled horizontal />
  </>
);

export { type NetworkSetupStepSchema, NetworkSetupSchemaValidation } from './NetworkSetupStepSchema';
