/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourcesDto } from './ResourcesDto';
export type NodeRequestDTO = {
  name: string;
  networkName: string;
  nodeResources: ResourcesDto;
  nodeType: NodeRequestDTO.nodeType;
  besuImage?: string;
  besuImageSecret?: string;
  logLevel?: NodeRequestDTO.logLevel;
  txPool?: NodeRequestDTO.txPool;
  txPoolLimitByAccountPercentage?: number;
  txPoolMaxSize?: number;
  revertReasonEnabled?: boolean;
};
export namespace NodeRequestDTO {
  export enum nodeType {
    VALIDATOR = 'VALIDATOR',
    BOOT = 'BOOT',
    RPC = 'RPC',
  }
  export enum logLevel {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
  }
  export enum txPool {
    LAYERED = 'LAYERED',
    SEQUENCED = 'SEQUENCED',
  }
}
