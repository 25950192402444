import * as Yup from 'yup';
import { Labels } from 'app/constants';
import * as steps from 'app/components/networks/create/steps';

export enum StepId {
  NETWORK_SETUP = 'networkSetup',
  RPC_NODE_GROUP = 'rpcNodeGroup',
  BLOCK_EXPLORER = 'blockExplorer',
  RESOURCES = 'resources',
  GENESIS_BLOCK = 'genesisBlock',
  SUMMARY = 'summary',
}

export type Step = {
  id: StepId;
  label: string;
  component: React.ElementType;
  validationSchema?: Yup.Schema<
    | steps.NetworkSetupStepSchema
    | steps.RpcNodeGroupStepSchema
    | steps.BlockExplorerSchema
    | steps.GenesisBlockStepSchema
    | steps.ResourcesStepSchema
  >;
};

/** Considered the maximum possible steps for a user -- optionally perform filtering logic in `generateSteps` if you need to */
export const baseSteps: Step[] = [
  {
    id: StepId.NETWORK_SETUP,
    label: Labels.CONFIGURATIONS,
    component: steps.NetworkSetupStep,
    validationSchema: steps.NetworkSetupSchemaValidation,
  },
  {
    id: StepId.GENESIS_BLOCK,
    label: Labels.CONFIGURE_GENESIS_BLOCK,
    component: steps.GenesisBlockStep,
    validationSchema: steps.GenesisBlockSchemaValidation,
  },
  {
    id: StepId.RESOURCES,
    label: Labels.RESOURCES,
    component: steps.ResourcesStep,
    validationSchema: steps.ResourcesSchemaValidation,
  },
  {
    id: StepId.RPC_NODE_GROUP,
    label: Labels.RPC_NODE_GROUP,
    component: steps.RpcNodeGroupStep,
    validationSchema: steps.RpcNodeGroupStepSchemaValidation,
  },
  {
    id: StepId.BLOCK_EXPLORER,
    label: Labels.BLOCK_EXPLORER,
    component: steps.BlockExplorerStep,
    validationSchema: steps.BlockExplorerSchemaValidation,
  },
  {
    id: StepId.SUMMARY,
    label: Labels.SUMMARY,
    component: steps.SummaryStep,
  },
];

export const generateSteps = (): Step[] => {
  // Depending on the type of user, and the requirements for the form that are needed -- you can perform filtering logic here to omit steps that aren't
  // necessary for each case
  return baseSteps;
};

export const getStepSchema = (currentIndex: number, stepsArr: Step[]) => {
  return stepsArr[currentIndex].validationSchema;
};
