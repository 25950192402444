/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NodeUpdateRequestDto = {
    logLevel?: NodeUpdateRequestDto.logLevel;
    txPool?: NodeUpdateRequestDto.txPool;
    txPoolLimitByAccountPercentage?: number;
    txPoolMaxSize?: number;
    revertReasonEnabled?: boolean;
};
export namespace NodeUpdateRequestDto {
    export enum logLevel {
        TRACE = 'TRACE',
        DEBUG = 'DEBUG',
        INFO = 'INFO',
        WARN = 'WARN',
        ERROR = 'ERROR',
    }
    export enum txPool {
        LAYERED = 'LAYERED',
        SEQUENCED = 'SEQUENCED',
    }
}

