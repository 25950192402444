import { useEffect } from 'react';
import {
  onSelectChangeAdapter,
  SelectInput,
  selectValueAdapter,
  TextInput,
  Toggle,
  Text,
} from '@intellecteu/common-ui';
import { Labels, noSqlDbStorage, sqlDbStorage, initialAlethioParams, initialMementoDto } from 'app/constants';
import { handleIntegerInput } from 'app/utils';
import { StepProps } from 'app/components/networks/types';
import { Field, StorageField } from 'app/components/core';
import { sqlDatabaseOptions } from 'app/components/networks/constants';
import { AlethioParamsDto, NoSqlDatabaseDto, SqlDatabaseDto } from 'data';

import s from './styles.module.scss';

export const BlockExplorerStep = ({ form: { values, setFieldValue, setFieldTouched, resetForm } }: StepProps) => {
  const { enableBlockExplorer, alethioParamsDto } = values;
  const sqlProvisionType = alethioParamsDto?.mementoDto?.sqlDatabase.provisionType;
  const noSqlProvisionType = alethioParamsDto?.mementoDto?.noSqlDatabase.provisionType;

  const isExternalSqlDB = sqlProvisionType === SqlDatabaseDto.provisionType.EXTERNAL;
  const isProvisionedSqlDB = sqlProvisionType === SqlDatabaseDto.provisionType.PROVISIONED;
  const isExternalNoSqlDB = noSqlProvisionType === NoSqlDatabaseDto.provisionType.EXTERNAL;
  const isProvisionedNoSqlDB = noSqlProvisionType === NoSqlDatabaseDto.provisionType.PROVISIONED;

  const resetAlethioParams = () =>
    resetForm({
      values: {
        ...values,
        alethioParamsDto: initialAlethioParams,
      },
    });

  const resetMementoDto = () =>
    resetForm({
      values: {
        ...values,
        alethioParamsDto: { ...(alethioParamsDto as AlethioParamsDto), mementoDto: initialMementoDto },
      },
    });

  useEffect(() => {
    !enableBlockExplorer && resetAlethioParams();
  }, [enableBlockExplorer]);

  useEffect(() => {
    !alethioParamsDto?.mementoToggle && resetMementoDto();
  }, [alethioParamsDto?.mementoToggle]);

  return (
    <>
      <Toggle.Small
        label={Labels.ENABLE_BLOCK_EXPLORER}
        checked={enableBlockExplorer}
        onChange={() => setFieldValue('enableBlockExplorer', !enableBlockExplorer)}
        horizontal
        disabled={values.rpcNodeGroup.rpcGroupName === ''}
      />

      {enableBlockExplorer && (
        <div className={s.section}>
          <Toggle.Small
            className={s.toggleControl}
            label={Labels.ENABLE_MEMENTO}
            checked={!!alethioParamsDto?.mementoToggle}
            onChange={() => setFieldValue('alethioParamsDto.mementoToggle', !alethioParamsDto?.mementoToggle)}
            horizontal
          />

          {alethioParamsDto?.mementoToggle && (
            <div className={s.section}>
              <Field
                component={TextInput}
                id="alethioParamsDto.mementoDto.image"
                name="alethioParamsDto.mementoDto.image"
                label={`${Labels.MEMENTO_IMAGE}*`}
              />

              <Text className={s.sectionTitle} size="s" weight="bold">
                {Labels.POSTGRES_CONNECTION}
              </Text>

              <Field
                options={sqlDatabaseOptions}
                component={SelectInput}
                id="alethioParamsDto.mementoDto.sqlDatabase.provisionType"
                name="alethioParamsDto.mementoDto.sqlDatabase.provisionType"
                label={`${Labels.TYPE}*`}
                onChangeAdapter={onSelectChangeAdapter}
                valueAdapter={selectValueAdapter(sqlDatabaseOptions)}
              />

              {isExternalSqlDB && (
                <>
                  <div className={s.dbForm}>
                    <Field
                      component={TextInput}
                      id="alethioParamsDto.mementoDto.sqlDatabase.host"
                      name="alethioParamsDto.mementoDto.sqlDatabase.host"
                      label={`${Labels.HOST}*`}
                    />

                    <Field
                      component={TextInput}
                      id="alethioParamsDto.mementoDto.sqlDatabase.port"
                      name="alethioParamsDto.mementoDto.sqlDatabase.port"
                      label={`${Labels.PORT}*`}
                      onKeyPress={handleIntegerInput}
                    />

                    <Field
                      component={TextInput}
                      id="alethioParamsDto.mementoDto.sqlDatabase.username"
                      name="alethioParamsDto.mementoDto.sqlDatabase.username"
                      label={`${Labels.USERNAME}*`}
                      data-1p-ignore
                    />

                    <Field
                      component={TextInput}
                      type="password"
                      id="alethioParamsDto.mementoDto.sqlDatabase.password"
                      name="alethioParamsDto.mementoDto.sqlDatabase.password"
                      label={`${Labels.PASSWORD}*`}
                      data-1p-ignore
                    />
                  </div>

                  <Field
                    component={TextInput}
                    id="alethioParamsDto.mementoDto.sqlDatabase.dbname"
                    name="alethioParamsDto.mementoDto.sqlDatabase.dbname"
                    label={`${Labels.DATABASE_NAME}*`}
                  />
                </>
              )}

              {isProvisionedSqlDB && (
                <StorageField
                  fieldName="sqlDatabaseStorage"
                  setFieldTouched={setFieldTouched}
                  min={sqlDbStorage.value as number}
                />
              )}

              <Text className={s.sectionTitle} size="s" weight="bold">
                {Labels.REDIS_CONNECTION}
              </Text>

              <Field
                options={sqlDatabaseOptions}
                component={SelectInput}
                id="alethioParamsDto.mementoDto.noSqlDatabase.provisionType"
                name="alethioParamsDto.mementoDto.noSqlDatabase.provisionType"
                label={`${Labels.TYPE}*`}
                onChangeAdapter={onSelectChangeAdapter}
                valueAdapter={selectValueAdapter(sqlDatabaseOptions)}
              />

              {isExternalNoSqlDB && (
                <>
                  <div className={s.dbForm}>
                    <Field
                      component={TextInput}
                      id="alethioParamsDto.mementoDto.noSqlDatabase.host"
                      name="alethioParamsDto.mementoDto.noSqlDatabase.host"
                      label={`${Labels.HOST}*`}
                    />

                    <Field
                      component={TextInput}
                      id="alethioParamsDto.mementoDto.noSqlDatabase.port"
                      name="alethioParamsDto.mementoDto.noSqlDatabase.port"
                      label={`${Labels.PORT}*`}
                      onKeyPress={handleIntegerInput}
                    />
                  </div>

                  <Field
                    component={TextInput}
                    type="password"
                    id="alethioParamsDto.mementoDto.noSqlDatabase.password"
                    name="alethioParamsDto.mementoDto.noSqlDatabase.password"
                    label={`${Labels.PASSWORD}*`}
                    data-1p-ignore
                  />

                  <Field
                    component={TextInput}
                    id="alethioParamsDto.mementoDto.noSqlDatabase.dbname"
                    name="alethioParamsDto.mementoDto.noSqlDatabase.dbname"
                    label={`${Labels.DATABASE_NAME}*`}
                  />
                </>
              )}

              {isProvisionedNoSqlDB && (
                <StorageField
                  fieldName="noSqlDatabaseStorage"
                  setFieldTouched={setFieldTouched}
                  min={noSqlDbStorage.value as number}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { type BlockExplorerSchema, BlockExplorerSchemaValidation } from './BlockExplorerStepSchema';
