import { useContext } from 'react';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { InfoItem, Text } from '@intellecteu/common-ui';
import { NetworkStepperContext, TNetworkStepperContext } from 'app/pages/networks/create/context';
import { StepField, StepSection } from 'app/components/networks/types';
import { StepId } from 'app/pages/networks/create/steps';
import { isEmpty } from 'app/utils/helpers';
import { CustomErrorMessage } from 'app/components/core';

import s from './styles.module.scss';

interface StepSummaryProps {
  index: number;
  stepId: StepId;
  stepLabel: string;
  sections: StepSection[];
}

export const StepSummary = ({ index, stepId, stepLabel, sections }: StepSummaryProps) => {
  const { errors } = useFormikContext();
  const { hasStepError } = useContext<TNetworkStepperContext>(NetworkStepperContext);
  const hasError: boolean = hasStepError(stepId) && !isEmpty(errors);

  return (
    <>
      <Text
        className={cx(s.stepTitle, hasError && s.errorTitle)}
        color={hasError ? Text.color.error : Text.color.darkBlue}
        weight="bold"
        size="s"
      >
        Step {index + 1}: {stepLabel}
      </Text>

      {sections?.map(({ title, fields, isVisible = true }: StepSection, sectionIdx: number) =>
        isVisible ? (
          <div key={sectionIdx} className={s.stepSummary}>
            {title && (
              <Text size="s" weight="bold">
                {title}
              </Text>
            )}

            {fields.map(({ name, label, value, isVisible: isFieldVisible = true }: StepField) => {
              if (!isFieldVisible) return null;

              if (String(value) && label) {
                return (
                  <div key={name}>
                    <InfoItem className={s.stepSummaryItem} label={label} value={String(value)} />
                    <CustomErrorMessage name={name} />
                  </div>
                );
              }

              return value ? (
                <div key={name}>
                  <InfoItem className={s.stepSummaryItem} label={label} value={value} />
                  <CustomErrorMessage name={name} />
                </div>
              ) : null;
            })}
          </div>
        ) : null,
      )}
    </>
  );
};
