/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type VoteDto = {
    votingAddress?: string;
    votingType?: VoteDto.votingType;
};
export namespace VoteDto {
    export enum votingType {
        ADD = 'ADD',
        REMOVE = 'REMOVE',
    }
}

