/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SqlDatabaseDto = {
  provisionType?: SqlDatabaseDto.provisionType;
  dbname?: string;
  host?: string;
  port?: number | string;
  schemaMigrationMode?: SqlDatabaseDto.schemaMigrationMode;
  username?: string;
  password?: string;
  sslMode?: SqlDatabaseDto.sslMode;
  storageRequest?: string;
};
export namespace SqlDatabaseDto {
  export enum provisionType {
    PROVISIONED = 'PROVISIONED',
    EXTERNAL = 'EXTERNAL',
  }
  export enum schemaMigrationMode {
    SAME_USER = 'SAME_USER',
  }
  export enum sslMode {
    REQUIRED = 'REQUIRED',
    DISABLED = 'DISABLED',
  }
}
