export * from './resources';
export * from './input-handlers';
export * from './date-parsers';
export * from './permissions';

export const capitalize = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const createNestedObject = (obj: object): object => {
  const result = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const nestedKeys = key.split('.');
    let current = result;

    nestedKeys.forEach((nestedKey: string, index: number) => {
      if (index === nestedKeys.length - 1) {
        current[nestedKey] = value;
      } else {
        current = current[nestedKey] = current[nestedKey] || {};
      }
    });
  });

  return result;
};

const downloadFile = (content: string | Blob, filename: string) => {
  const url = window.URL.createObjectURL(typeof content !== 'string' ? content : new Blob([content]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export { downloadFile };

export const isEmpty = (obj: object): boolean => {
  return Object.entries(obj).length === 0;
};

export const prepareFieldValue = (value?: string) => (value !== '' ? value : undefined);
