/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionsDto } from './PermissionsDto';
export type UserResponseDto = {
    username?: string;
    permissions?: Array<PermissionsDto>;
    userState?: UserResponseDto.userState;
    createdBy?: string;
    creationDate?: string;
    rpcNodeGroupName?: string;
};
export namespace UserResponseDto {
    export enum userState {
        CREATING = 'CREATING',
        READY = 'READY',
        FAILED = 'FAILED',
        REJECTED = 'REJECTED',
    }
}

