/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockExplorerResourcesDto } from './BlockExplorerResourcesDto';
import type { MementoParamsDto } from './MementoParamsDto';
export type BlockExplorerResponseDto = {
    name?: string;
    status?: BlockExplorerResponseDto.status;
    publicUrl?: string;
    hasDataSource?: boolean;
    rpcNodeGroupName?: string;
    blockExplorerResources?: BlockExplorerResourcesDto;
    mementoParams?: MementoParamsDto;
};
export namespace BlockExplorerResponseDto {
    export enum status {
        INITIALIZING = 'INITIALIZING',
        INIT_FAIL = 'INIT_FAIL',
        INIT_OK = 'INIT_OK',
        CRASHED = 'CRASHED',
        RUNNING = 'RUNNING',
        UPDATING = 'UPDATING',
    }
}

