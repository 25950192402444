/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionsDto } from './PermissionsDto';
import type { ResourcesDto } from './ResourcesDto';
export type RpcNodeGroupDto = {
  rpcGroupName?: string;
  hostsAllowList?: Array<string>;
  rpcAuthStrategy: RpcNodeGroupDto.rpcAuthStrategy;
  maxNumOfNodes?: number;
  resourcesPerNode: ResourcesDto;
  apiGroups: Array<string>; // keys in lowercase format from RPC_PERMISSIONS_OPTIONS env variable
  rpcOpenIDConfigurationURL?: string;
  methodsWithNoAuth?: Array<PermissionsDto>;
};
export namespace RpcNodeGroupDto {
  export enum rpcAuthStrategy {
    CREDENTIALS = 'CREDENTIALS',
    NONE = 'NONE',
    OPENID = 'OPENID',
  }
}
