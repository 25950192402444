import * as Yup from 'yup';
import { UnitAdapter } from 'app/constants';
import { NetworkRequestDTO } from 'data';
import { besuImageSchema, besuImageSecretSchema, defaultNameSchema } from 'data/utils/validation';

type NodeResource = Omit<UnitAdapter, 'unit'>;

export interface NodeResources {
  cpuLimit: NodeResource;
  memoryLimit: NodeResource;
  cpuRequested: NodeResource;
  memoryRequested: NodeResource;
  storageRequested: NodeResource;
}

export type NetworkSetupStepSchema = Pick<NetworkRequestDTO, 'name' | 'besuImage' | 'besuImageSecret'>;

export const NetworkSetupSchemaValidation: Yup.Schema<NetworkSetupStepSchema> = Yup.object({
  name: defaultNameSchema,
  besuImage: besuImageSchema,
  besuImageSecret: besuImageSecretSchema,
});
