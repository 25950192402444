import { useEffect, useState } from 'react';
import {
  SelectInput,
  Text,
  TextInput,
  selectValueAdapter,
  onSelectChangeAdapter,
  InfoItem,
  onSelectMultiChangeAdapter,
  selectMultiValueAdapter,
  Radio,
  IconButton,
  Icon,
  Button,
} from '@intellecteu/common-ui';
import { FieldArray } from 'formik';
import { apiGroupOptions, Labels } from 'app/constants';
import { handleIntegerInput } from 'app/utils';
import { Field, Resources } from 'app/components/core';
import { authTypesOptions, testRpcConfigUrl } from 'app/components/networks/constants';
import { StepProps } from 'app/components/networks/types';
import { RpcNodeGroupDto } from 'data';
import { DEFAULT_NAME_VALIDATION_NOTE } from 'data/utils/validation';

import s from './styles.module.scss';

export const RpcNodeGroupStep = ({
  form: {
    values: {
      rpcNodeGroup: { rpcAuthStrategy, rpcOpenIDConfigurationURL: rpcConfigUrl, hostsAllowList, rpcGroupName },
    },
    setFieldValue,
    setFieldTouched,
    errors,
  },
}: StepProps) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isRpcNodeGroupSetUpped, setIsRpcNodeGroupSetUpped] = useState(false);
  const [fieldLabel, setFieldLabel] = useState('');
  const isOpenIDAuth = rpcAuthStrategy === RpcNodeGroupDto.rpcAuthStrategy.OPENID;
  const isCredentialsAuth = rpcAuthStrategy === RpcNodeGroupDto.rpcAuthStrategy.CREDENTIALS;

  const onSelectHosts = (isCreateMode: boolean) => {
    setShowCreateForm(isCreateMode);
    setFieldValue('rpcNodeGroup.hostsAllowList', isCreateMode ? [''] : ['*']);
  };

  const selectAnyHosts = () => onSelectHosts(false);
  const selectCreateHosts = () => onSelectHosts(true);

  useEffect(() => {
    if (isOpenIDAuth) {
      setFieldValue('rpcNodeGroup.rpcOpenIDConfigurationURL', rpcConfigUrl || testRpcConfigUrl, false);
    } else {
      setFieldValue('rpcNodeGroup.rpcOpenIDConfigurationURL', '', false);
    }
  }, [rpcAuthStrategy]);

  useEffect(() => {
    setIsRpcNodeGroupSetUpped(!!rpcGroupName);
    setFieldLabel(rpcGroupName !== '' ? '*' : '');
  }, [rpcGroupName]);

  return (
    <>
      <Field
        component={TextInput}
        id="rpcNodeGroup.rpcGroupName"
        name="rpcNodeGroup.rpcGroupName"
        label={`${Labels.NODE_GROUP_NAME}${fieldLabel}`}
        tooltipText={DEFAULT_NAME_VALIDATION_NOTE}
        data-1p-ignore
        autoFocus
      />

      <div>
        <Field
          className={isCredentialsAuth && s.fieldWithoutMargin}
          options={authTypesOptions}
          component={SelectInput}
          id="rpcNodeGroup.rpcAuthStrategy"
          name="rpcNodeGroup.rpcAuthStrategy"
          label={`${Labels.RPC_AUTH_STRATEGY}${fieldLabel}`}
          onChangeAdapter={onSelectChangeAdapter}
          valueAdapter={selectValueAdapter(authTypesOptions)}
          isDisabled={!isRpcNodeGroupSetUpped}
        />

        {isCredentialsAuth && (
          <Text className={s.credentialsNote} size={Text.size.xxs}>
            {Labels.AUTH_CREDENTIALS_NOTE}
          </Text>
        )}

        {isOpenIDAuth && (
          <Field
            component={TextInput}
            id="rpcNodeGroup.rpcOpenIDConfigurationURL"
            name="rpcNodeGroup.rpcOpenIDConfigurationURL"
            label={Labels.RPC_CONFIG_URL}
            disabled={!isRpcNodeGroupSetUpped}
          />
        )}
      </div>

      <Field
        component={TextInput}
        id="rpcNodeGroup.maxNumOfNodes"
        name="rpcNodeGroup.maxNumOfNodes"
        label={`${Labels.MAXIMUM_NODES_NUMBER}${fieldLabel}`}
        onKeyPress={handleIntegerInput}
        disabled={!isRpcNodeGroupSetUpped}
      />

      <Field
        options={apiGroupOptions}
        component={SelectInput}
        id="rpcNodeGroup.apiGroups"
        name="rpcNodeGroup.apiGroups"
        label={`${Labels.API_GROUPS}${fieldLabel}`}
        onChangeAdapter={onSelectMultiChangeAdapter}
        valueAdapter={selectMultiValueAdapter(apiGroupOptions)}
        isMulti
        isDisabled={!isRpcNodeGroupSetUpped}
      />

      <InfoItem
        className={s.formSidebarSectionTitle}
        label={Labels.RESOURCES_PER_NODE}
        labelSize="s"
        labelColor="dark"
        labelWeight="bold"
      />

      <Resources
        startName="rpcNodeGroup.resourcesPerNode"
        errors={errors}
        setFieldTouched={setFieldTouched}
        disabled={!isRpcNodeGroupSetUpped}
      />

      <InfoItem
        className={s.formSidebarSectionTitle}
        label={Labels.HOSTS_ALLOWLIST}
        labelSize="s"
        labelColor="dark"
        labelWeight="bold"
      />

      <div className={s.hostsForm}>
        <Radio
          name="hosts"
          label={Labels.ANY}
          checked={!showCreateForm}
          onChange={selectAnyHosts}
          disabled={!isRpcNodeGroupSetUpped}
        />

        <Radio
          name="hosts"
          label={Labels.CREATE}
          checked={showCreateForm}
          onChange={selectCreateHosts}
          disabled={!isRpcNodeGroupSetUpped}
        />

        {showCreateForm && (
          <FieldArray
            name="rpcNodeGroup.hostsAllowList"
            render={({ push, remove }) => (
              <ul>
                {hostsAllowList?.map((_, index) => (
                  <li key={`host-${index}`} className={s.hostsFormControl}>
                    <Field
                      component={TextInput}
                      className={s.hostsFormControlInput}
                      id={`rpcNodeGroup.hostsAllowList.${index}`}
                      name={`rpcNodeGroup.hostsAllowList.${index}`}
                      label={`${Labels.ENTER_HOSTNAME}${fieldLabel}`}
                      isDisabled={!isRpcNodeGroupSetUpped}
                    />

                    {index > 0 && (
                      <IconButton
                        iconSize="s"
                        onClick={() => remove(index)}
                        iconName={Icon.icons.remove}
                        tooltipText="Remove"
                        disabled={!isRpcNodeGroupSetUpped}
                      />
                    )}
                  </li>
                ))}

                <div className={s.hostsFormAddBtn}>
                  <Button variant="tertiary" size="xs" onClick={() => push('')} disabled={!isRpcNodeGroupSetUpped}>
                    <Icon icon={Icon.icons.add} size="m" />
                    Add host
                  </Button>
                </div>
              </ul>
            )}
          />
        )}
      </div>
    </>
  );
};

export { type RpcNodeGroupStepSchema, RpcNodeGroupStepSchemaValidation } from './RpcNodeGroupSchema';
