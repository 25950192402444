import * as Yup from 'yup';
import { memoryUnitSize, noSqlDbStorage, noSqlDbStorageMax, sqlDbStorage, sqlDbStorageMax } from 'app/constants';
import { AlethioParamsDto, NoSqlDatabaseDto, SqlDatabaseDto } from 'data';
import {
  getMaxLengthErrorMessage,
  getMaxValueErrorMessage,
  getMinValueErrorMessage,
  INTEGER_ERROR_MESSAGE,
  INVALID_ERROR_MESSAGE,
  memoryResourcesRegExp,
  REQUIRED_ERROR_MESSAGE,
} from 'data/utils/validation';

const dbNameRegExp = /^[a-z_][a-z0-9_]*$/;

export type BlockExplorerSchema = {
  enableBlockExplorer?: boolean;
  alethioParamsDto?: AlethioParamsDto | object;
  sqlDatabaseStorage: { value?: string };
  noSqlDatabaseStorage: { value?: string };
};

const requiredStringIfProvisionType = (type: SqlDatabaseDto.provisionType | NoSqlDatabaseDto.provisionType) =>
  Yup.string().when('provisionType', {
    is: type,
    then: (schema) => schema.required(REQUIRED_ERROR_MESSAGE),
  });

const requiredIntegerIfProvisionType = (type: SqlDatabaseDto.provisionType | NoSqlDatabaseDto.provisionType) =>
  Yup.number()
    .integer(INTEGER_ERROR_MESSAGE)
    .when('provisionType', {
      is: type,
      then: (schema) => schema.required(REQUIRED_ERROR_MESSAGE),
    });

const dbNameSchema = (type: SqlDatabaseDto.provisionType | NoSqlDatabaseDto.provisionType) =>
  Yup.string().when('provisionType', {
    is: type,
    then: (schema) =>
      schema
        .required(REQUIRED_ERROR_MESSAGE)
        .matches(
          dbNameRegExp,
          'The name must start with a lowercase letter or underscore, and can contain digits, lowercase letters or underscores',
        )
        .max(63, getMaxLengthErrorMessage('Database name', 63)),
  });

export const BlockExplorerSchemaValidation: Yup.ObjectSchema<BlockExplorerSchema> = Yup.object({
  enableBlockExplorer: Yup.boolean(),
  alethioParamsDto: Yup.object().when('enableBlockExplorer', {
    is: true,
    then: (schema) =>
      schema.shape({
        mementoToggle: Yup.boolean().required(REQUIRED_ERROR_MESSAGE),
        mementoDto: Yup.object().when('mementoToggle', {
          is: true,
          then: (mementoSchema) =>
            mementoSchema.shape({
              image: Yup.string().required(REQUIRED_ERROR_MESSAGE),
              sqlDatabase: Yup.object({
                host: requiredStringIfProvisionType(SqlDatabaseDto.provisionType.EXTERNAL),
                port: requiredIntegerIfProvisionType(SqlDatabaseDto.provisionType.EXTERNAL),
                username: requiredStringIfProvisionType(SqlDatabaseDto.provisionType.EXTERNAL),
                password: requiredStringIfProvisionType(SqlDatabaseDto.provisionType.EXTERNAL),
                dbname: dbNameSchema(SqlDatabaseDto.provisionType.EXTERNAL),
              }),
              noSqlDatabase: Yup.object({
                host: requiredStringIfProvisionType(NoSqlDatabaseDto.provisionType.EXTERNAL),
                port: requiredIntegerIfProvisionType(NoSqlDatabaseDto.provisionType.EXTERNAL),
                password: requiredStringIfProvisionType(NoSqlDatabaseDto.provisionType.EXTERNAL),
                dbname: dbNameSchema(NoSqlDatabaseDto.provisionType.EXTERNAL),
              }),
            }),
        }),
      }),
  }),
  sqlDatabaseStorage: Yup.object({
    value: Yup.string()
      .matches(memoryResourcesRegExp, INVALID_ERROR_MESSAGE)
      .test('Minimum value', (value, context) => {
        const storageSize = parseFloat(value as string) * memoryUnitSize[context?.parent?.unit];
        const storageMin = Number(sqlDbStorage.value);

        return (
          Number(storageSize) >= storageMin * memoryUnitSize[sqlDbStorage.unit] ||
          context.createError({ message: getMinValueErrorMessage(storageMin, sqlDbStorage.unit) })
        );
      })
      .test('Maximum value', (value, context) => {
        const storageSize = parseFloat(value as string) * memoryUnitSize[context?.parent?.unit];
        const storageMax = Number(sqlDbStorageMax.value);

        return (
          Number(storageSize) <= storageMax * memoryUnitSize[sqlDbStorageMax.unit] ||
          context.createError({ message: getMaxValueErrorMessage(storageMax, sqlDbStorageMax.unit) })
        );
      }),
  }),
  noSqlDatabaseStorage: Yup.object({
    value: Yup.string()
      .matches(memoryResourcesRegExp, INVALID_ERROR_MESSAGE)
      .test('Minimum value', (value, context) => {
        const storageSize = parseFloat(value as string) * memoryUnitSize[context?.parent?.unit];
        const storageMin = Number(noSqlDbStorage.value);

        return (
          Number(storageSize) >= storageMin * memoryUnitSize[noSqlDbStorage.unit] ||
          context.createError({ message: getMinValueErrorMessage(storageMin, noSqlDbStorage.unit) })
        );
      })
      .test('Maximum value', (value, context) => {
        const storageSize = parseFloat(value as string) * memoryUnitSize[context?.parent?.unit];
        const storageMax = Number(noSqlDbStorageMax.value);

        return (
          Number(storageSize) <= storageMax * memoryUnitSize[noSqlDbStorageMax.unit] ||
          context.createError({ message: getMaxValueErrorMessage(storageMax, noSqlDbStorageMax.unit) })
        );
      }),
  }),
});
