import { AlethioParamsDto, MementoDto, NoSqlDatabaseDto, SqlDatabaseDto } from '../../data';

// export const alethioExplorerImage = window._env_?.ALETHIO_EXPLORER_IMAGE || import.meta.env.VITE_ALETHIO_EXPLORER_IMAGE;
export const alethioMementoImage = window._env_?.ALETHIO_MEMENTO_IMAGE || import.meta.env.VITE_ALETHIO_MEMENTO_IMAGE;

export const initialMementoDto: MementoDto = {
  image: alethioMementoImage,
  sqlDatabase: {
    provisionType: SqlDatabaseDto.provisionType.EXTERNAL,
    dbname: '',
    host: '',
    port: '',
    username: '',
    password: '',
  },
  noSqlDatabase: {
    provisionType: NoSqlDatabaseDto.provisionType.EXTERNAL,
    dbname: '',
    host: '',
    port: '',
    password: '',
  },
};

export const initialAlethioParams: AlethioParamsDto = {
  // image: alethioExplorerImage,
  mementoToggle: false,
  mementoDto: initialMementoDto,
};
