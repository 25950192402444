import { NodeResponseDTO } from 'data';

export const basePath = '/';

const applyBaseUrl = (url: string) => `${basePath}${url}`;

export const dashboardPath = applyBaseUrl('dashboard');

// -------------------- Networks ------------------- //
export const networksPath = applyBaseUrl('networks');
export const networkCreatePath = `${networksPath}/create`;
export const networkDetailsPath = (networkName: string) => `${networksPath}/${networkName}`;
export const createRpcUserPath = 'create-user';
export const editRpcUserPath = (userName: string) => `${userName}/edit-user`;

// -------------------- Nodes ------------------- //
export const nodesPath = applyBaseUrl('nodes');
export const nodesPathWithType = (nodeType: string) => `${nodesPath}/${nodeType}`;
export const validatorsPath = `${nodesPath}/${NodeResponseDTO.nodeType.VALIDATOR.toLowerCase()}`;
export const bootnodesPath = `${nodesPath}/${NodeResponseDTO.nodeType.BOOT.toLowerCase()}`;
export const nodeCreatePath = 'create-node';
export const nodeDetailsPath = (nodeType: string, nodeName: string, networkName: string) =>
  `${nodesPathWithType(nodeType)}/${networkName}/${nodeName}`;
export const nodeVotingsPath = 'create-voting';

// -------------------- RPC Node Groups ------------------- //
export const rpcGroupsPath = applyBaseUrl('rpc-node-groups');
export const rpcNodeGroupDetailsPath = (networkName: string, rpcGroupName: string) =>
  `${rpcGroupsPath}/${networkName}/${rpcGroupName}`;
export const rpcGroupCreatePath = `${rpcGroupsPath}/create`;

export const notFoundPath = applyBaseUrl('not-found');
