/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionsDto } from './PermissionsDto';
import type { ResourcesDto } from './ResourcesDto';
export type RpcNodeGroupRequestDto = {
  rpcGroupName?: string;
  besuImage?: string;
  besuImageSecret?: string | null;
  hostsAllowList?: Array<string>;
  networkName: string;
  rpcAuthStrategy: RpcNodeGroupRequestDto.rpcAuthStrategy;
  maxNumOfNodes?: number;
  resourcesPerNode: ResourcesDto;
  apiGroups: Array<string>; // keys in lowercase format from RPC_PERMISSIONS_OPTIONS env variable
  rpcOpenIDConfigurationURL?: string | null; // 400: "RPC OpenID configuration URL must be null for NONE and CREDENTIALS RPC Auth strategies"
  methodsWithNoAuth?: Array<PermissionsDto>;
};
export namespace RpcNodeGroupRequestDto {
  export enum rpcAuthStrategy {
    CREDENTIALS = 'CREDENTIALS',
    NONE = 'NONE',
    OPENID = 'OPENID',
  }
}
