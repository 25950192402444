import { InfoItem, TextInput } from '@intellecteu/common-ui';
import { Labels, minNodesNum } from 'app/constants';
import { handleIntegerInput } from 'app/utils';
import { Field, Resources } from 'app/components/core';
import { StepProps } from 'app/components/networks/types';

import s from './styles.module.scss';

export const ResourcesStep = ({ form: { values, errors, setFieldTouched } }: StepProps) => (
  <>
    <InfoItem
      className={s.formSidebarSectionTitle}
      label={Labels.VALIDATOR_RESOURCES}
      labelSize="s"
      labelColor="dark"
      labelWeight="bold"
    />

    <Resources
      startName="validatorResources"
      className={s.sectionWithDivider}
      errors={errors}
      setFieldTouched={setFieldTouched}
    >
      <Field
        component={TextInput}
        id="numOfNodesDto.validatorsNum"
        name="numOfNodesDto.validatorsNum"
        label={`${Labels.VALIDATORS_NUM}*`}
        placeholder={`Min ${minNodesNum[values.consensusMechanism].validatorsNum}`}
        onKeyPress={handleIntegerInput}
      />
    </Resources>

    <InfoItem
      className={s.formSidebarSectionTitle}
      label={Labels.BOOTNODE_RESOURCES}
      labelSize="s"
      labelColor="dark"
      labelWeight="bold"
    />

    <Resources
      startName="bootNodeResources"
      className={s.sectionWithDivider}
      errors={errors}
      setFieldTouched={setFieldTouched}
      isBoot
    >
      <Field
        component={TextInput}
        id="numOfNodesDto.bootnodesNum"
        name="numOfNodesDto.bootnodesNum"
        label={`${Labels.BOOTNODES_NUM}*`}
        placeholder={`Min ${minNodesNum[values.consensusMechanism].bootnodesNum}`}
        onKeyPress={handleIntegerInput}
      />
    </Resources>
  </>
);

export { type ResourcesStepSchema, ResourcesSchemaValidation } from './ResourcesStepSchema';
