/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourcesDto } from './ResourcesDto';

export type NodeResponseDTO = {
  name: string;
  networkName: string;
  nodeType: NodeResponseDTO.nodeType;
  nodeAddress?: string;
  nodeUrl?: string;
  nodeStatus?: NodeResponseDTO.nodeStatus;
  createdBy?: string;
  nodeCreationDate?: string;
  /**
   * A node is created externally
   */
  external?: boolean;
  resources?: ResourcesDto;
  besuImage?: string;
  besuImageSecret?: string;
  nodeGroupName?: string;
  storageUsedPercentage?: string;
  logLevel?: NodeResponseDTO.logLevel;
  txPool?: NodeResponseDTO.txPool;
  txPoolLimitByAccountPercentage?: string;
  txPoolMaxSize?: number;
  revertReasonEnabled?: boolean;
};
export namespace NodeResponseDTO {
  export enum nodeType {
    VALIDATOR = 'VALIDATOR',
    BOOT = 'BOOT',
    RPC = 'RPC',
  }

  export enum nodeStatus {
    INITIALIZING = 'INITIALIZING',
    INITFAILED = 'INITFAILED',
    INITSUCCEEDED = 'INITSUCCEEDED',
    RUNNING = 'RUNNING',
    CRASHED = 'CRASHED',
    UPDATING = 'UPDATING',
  }
  export enum logLevel {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
  }
  export enum txPool {
    LAYERED = 'LAYERED',
    SEQUENCED = 'SEQUENCED',
  }
}
