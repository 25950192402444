import { Status, type TOption, selectOptionsAdapter } from '@intellecteu/common-ui';
import { Labels, TStatusConfig } from 'app/constants';
import { unitSplitter } from 'app/utils/helpers';
import { type InitNetworkDto } from 'app/pages/networks/create';
import { StepId } from 'app/pages/networks/create/steps';
import {
  NetworkNodeTypeStats,
  NetworkRequestDTO,
  NetworkResponseDTO,
  NodeRequestDTO,
  NodeResponseDTO,
  NoSqlDatabaseDto,
  RpcNodeGroupDto,
  SqlDatabaseDto,
} from 'data';
import type { StepConfig } from './types';

export const testRpcConfigUrl = window._env_?.TEST_RPC_OPENID_CONF_URL || import.meta.env.VITE_TEST_RPC_OPENID_CONF_URL;

export const authTypesOptions: TOption[] = [
  {
    label: Labels.DISABLED,
    value: RpcNodeGroupDto.rpcAuthStrategy.NONE,
  },
  {
    label: Labels.OPEN_ID,
    value: RpcNodeGroupDto.rpcAuthStrategy.OPENID,
  },
  {
    label: Labels.CREDENTIALS,
    value: RpcNodeGroupDto.rpcAuthStrategy.CREDENTIALS,
  },
];
export const sqlDatabaseOptions: TOption[] = [
  {
    label: Labels.EXTERNAL,
    value: SqlDatabaseDto.provisionType.EXTERNAL,
  },
  {
    label: Labels.PROVISIONED,
    value: SqlDatabaseDto.provisionType.PROVISIONED,
  },
];

export const protocolsList: string[] = Object.values(NetworkRequestDTO.consensusMechanism);
export const consensusOptions: TOption[] = selectOptionsAdapter(protocolsList);

export const networkStatusConfig: TStatusConfig = {
  [NetworkResponseDTO.state.INITIALIZING]: {
    mode: Status.mode.processing,
    label: 'Initializing',
  },
  [NetworkResponseDTO.state.INITSUCCEEDED]: {
    mode: Status.mode.success,
    label: 'Init succeeded',
  },
  [NetworkResponseDTO.state.INITFAILED]: {
    mode: Status.mode.error,
    label: 'Init failed',
  },
  [NetworkResponseDTO.state.QUORUMALIVE]: {
    mode: Status.mode.success,
    label: 'Quorum alive',
  },
  [NetworkResponseDTO.state.QUORUMFAILED]: {
    mode: Status.mode.error,
    label: 'Quorum failed',
  },
};

export const getNodeStatistics = (nodeTypeStats?: Record<NodeRequestDTO.nodeType, NetworkNodeTypeStats>) => {
  const getAvailableNodes = (nodeType: NodeResponseDTO.nodeType) => nodeTypeStats?.[nodeType]?.available;
  const getTotalNodes = (nodeType: NodeResponseDTO.nodeType) => nodeTypeStats?.[nodeType]?.total;

  const availableValidators = getAvailableNodes(NodeResponseDTO.nodeType.VALIDATOR);
  const availableBootnodes = getAvailableNodes(NodeResponseDTO.nodeType.BOOT);
  const availableRPCNodes = getAvailableNodes(NodeResponseDTO.nodeType.RPC);

  const totalValidators = getTotalNodes(NodeResponseDTO.nodeType.VALIDATOR);
  const totalBootnodes = getTotalNodes(NodeResponseDTO.nodeType.BOOT);
  const totalRPCNodes = getTotalNodes(NodeResponseDTO.nodeType.RPC);

  const hasValidatorStats = availableValidators != null && totalValidators != null;
  const hasBootStats = availableBootnodes != null && totalBootnodes != null;
  const hasRPCStats = availableRPCNodes != null && totalRPCNodes != null;

  return {
    availableValidators,
    availableBootnodes,
    availableRPCNodes,
    totalValidators,
    totalBootnodes,
    totalRPCNodes,
    hasValidatorStats,
    hasBootStats,
    hasRPCStats,
  };
};

export const getStepSummaryConfig = (values: InitNetworkDto): StepConfig => {
  const {
    name,
    besuImage,
    besuImageSecret,
    consensusMechanism,
    numOfNodesDto: { validatorsNum, bootnodesNum },
    rpcNodeGroup: {
      rpcGroupName,
      hostsAllowList,
      rpcAuthStrategy,
      maxNumOfNodes,
      resourcesPerNode,
      apiGroups,
      rpcOpenIDConfigurationURL,
      methodsWithNoAuth,
    },
    validatorResources,
    bootNodeResources,
    genesisConfigDto: { consensusAlgoBlockperiodseconds },
    genesisFile,
    enableBlockExplorer,
    alethioParamsDto,
    sqlDatabaseStorage,
    noSqlDatabaseStorage,
  } = values;
  const genesisStepConfig = genesisFile?.length
    ? [
        {
          fields: [
            {
              label: Labels.CONSENSUS,
              value: consensusMechanism,
              name: 'consensusMechanism',
            },
            {
              label: Labels.GENESIS_FILE,
              value: genesisFile[0].name,
              name: 'genesisFile',
            },
          ],
        },
      ]
    : [
        {
          fields: [
            {
              label: Labels.CONSENSUS,
              value: consensusMechanism,
              name: 'consensusMechanism',
            },
            {
              label: Labels.BLOCK_PERIOD,
              value: String(consensusAlgoBlockperiodseconds),
              name: 'genesisConfigDto.consensusAlgoBlockperiodseconds',
            },
          ],
        },
      ];

  const sqlDbFields =
    alethioParamsDto?.mementoDto?.sqlDatabase.provisionType === SqlDatabaseDto.provisionType.EXTERNAL
      ? [
          {
            label: Labels.TYPE,
            value: alethioParamsDto?.mementoDto?.sqlDatabase.provisionType,
            name: 'alethioParamsDto.mementoDto.sqlDatabase.provisionType',
          },
          {
            label: Labels.HOST,
            value: alethioParamsDto?.mementoDto?.sqlDatabase.host,
            name: 'alethioParamsDto.mementoDto.sqlDatabase.host',
          },
          {
            label: Labels.PORT,
            value: alethioParamsDto?.mementoDto?.sqlDatabase.port,
            name: 'alethioParamsDto.mementoDto.sqlDatabase.port',
          },
          {
            label: Labels.USERNAME,
            value: alethioParamsDto?.mementoDto?.sqlDatabase.username,
            name: 'alethioParamsDto.mementoDto.sqlDatabase.username',
          },
          {
            label: Labels.PASSWORD,
            value: alethioParamsDto?.mementoDto?.sqlDatabase.password,
            name: 'alethioParamsDto.mementoDto.sqlDatabase.password',
          },
          {
            label: Labels.DATABASE_NAME,
            value: alethioParamsDto?.mementoDto?.sqlDatabase.dbname,
            name: 'alethioParamsDto.mementoDto.sqlDatabase.dbname',
          },
        ]
      : [
          {
            label: Labels.STORAGE,
            value: unitSplitter(sqlDatabaseStorage),
            name: 'sqlDatabaseStorage',
          },
        ];

  const noSqlDbFields =
    alethioParamsDto?.mementoDto?.noSqlDatabase.provisionType === NoSqlDatabaseDto.provisionType.EXTERNAL
      ? [
          {
            label: Labels.TYPE,
            value: alethioParamsDto?.mementoDto?.noSqlDatabase.provisionType,
            name: 'alethioParamsDto.mementoDto.noSqlDatabase.provisionType',
          },
          {
            label: Labels.HOST,
            value: alethioParamsDto?.mementoDto?.noSqlDatabase.host,
            name: 'alethioParamsDto.mementoDto.noSqlDatabase.host',
          },
          {
            label: Labels.PORT,
            value: alethioParamsDto?.mementoDto?.noSqlDatabase.port,
            name: 'alethioParamsDto.mementoDto.sqlDatabase.port',
          },
          {
            label: Labels.PASSWORD,
            value: alethioParamsDto?.mementoDto?.noSqlDatabase.password,
            name: 'alethioParamsDto.mementoDto.noSqlDatabase.password',
          },
          {
            label: Labels.DATABASE_NAME,
            value: alethioParamsDto?.mementoDto?.noSqlDatabase.dbname,
            name: 'alethioParamsDto.mementoDto.noSqlDatabase.dbname',
          },
        ]
      : [
          {
            label: Labels.STORAGE,
            value: unitSplitter(noSqlDatabaseStorage),
            name: 'noSqlDatabaseStorage',
          },
        ];

  return {
    [StepId.NETWORK_SETUP]: [
      {
        fields: [
          {
            label: Labels.NETWORK_NAME,
            value: name,
            name: 'name',
          },
          {
            label: Labels.BESU_IMAGE,
            value: besuImage,
            name: 'besuImage',
          },
          {
            label: Labels.BESU_IMAGE_SECRET,
            value: besuImageSecret,
            name: 'besuImageSecret',
          },
        ],
      },
    ],
    [StepId.RPC_NODE_GROUP]: [
      {
        fields: [
          {
            label: Labels.NODE_GROUP_NAME,
            value: rpcGroupName,
            name: 'rpcNodeGroup.rpcGroupName',
          },
          {
            label: Labels.HOSTS_ALLOWLIST,
            value: hostsAllowList?.join(', '),
            name: 'rpcNodeGroup.hostsAllowList',
          },
          {
            label: Labels.RPC_AUTH_STRATEGY,
            value: authTypesOptions.find((type) => type.value === rpcAuthStrategy)?.label as string,
            name: 'rpcNodeGroup.rpcAuthStrategy',
          },
          {
            label: Labels.MAXIMUM_NODES_NUMBER,
            value: maxNumOfNodes,
            name: 'rpcNodeGroup.maxNumOfNodes',
          },
          {
            label: Labels.RPC_CONFIG_URL,
            value: rpcOpenIDConfigurationURL,
            name: 'rpcNodeGroup.rpcOpenIDConfigurationURL',
          },
          {
            label: Labels.API_GROUPS,
            value: apiGroups?.join(', '),
            name: 'rpcNodeGroup.apiGroups',
          },
          {
            label: Labels.METHODS_WITH_NO_AUTH,
            value: methodsWithNoAuth?.join(', '),
            name: 'rpcNodeGroup.methodsWithNoAuth',
          },
        ],
      },
      {
        title: 'RPC node resources',
        fields: [
          {
            label: Labels.RPC_NODES_NUM,
            value: String(maxNumOfNodes),
            name: 'rpcNodeGroup.maxNumOfNodes',
          },
          {
            label: Labels.REQUESTED_CPU,
            value: unitSplitter(resourcesPerNode.cpuRequested),
            name: 'rpcNodeGroup.resourcesPerNode.cpuRequested',
          },
          {
            label: Labels.CPU_LIMIT,
            value: unitSplitter(resourcesPerNode.cpuLimit),
            name: 'rpcNodeGroup.resourcesPerNode.cpuLimit',
          },
          {
            label: Labels.REQUESTED_MEMORY,
            value: unitSplitter(resourcesPerNode.memoryRequested),
            name: 'rpcNodeGroup.resourcesPerNode.memoryRequested',
          },
          {
            label: Labels.MEMORY_LIMIT,
            value: unitSplitter(resourcesPerNode.memoryLimit),
            name: 'rpcNodeGroup.resourcesPerNode.memoryLimit',
          },
          {
            label: Labels.STORAGE,
            value: unitSplitter(resourcesPerNode.storageRequested),
            name: 'rpcNodeGroup.resourcesPerNode.storageRequested',
          },
        ],
      },
    ],
    [StepId.BLOCK_EXPLORER]: [
      {
        fields: [
          {
            label: Labels.ENABLE_BLOCK_EXPLORER,
            value: enableBlockExplorer,
            name: 'enableBlockExplorer',
          },
          {
            label: Labels.ENABLE_MEMENTO,
            value: alethioParamsDto?.mementoToggle,
            name: 'alethioParamsDto.mementoToggle',
          },
          {
            label: Labels.MEMENTO_IMAGE,
            value: alethioParamsDto?.mementoDto?.image,
            name: 'alethioParamsDto.mementoDto.image',
            isVisible: alethioParamsDto?.mementoToggle,
          },
        ],
      },
      {
        title: Labels.POSTGRES_CONNECTION,
        fields: sqlDbFields,
        isVisible: alethioParamsDto?.mementoToggle,
      },
      {
        title: Labels.REDIS_CONNECTION,
        fields: noSqlDbFields,
        isVisible: alethioParamsDto?.mementoToggle,
      },
    ],
    [StepId.GENESIS_BLOCK]: genesisStepConfig,
    [StepId.RESOURCES]: [
      {
        title: 'Validator resources',
        fields: [
          {
            label: Labels.VALIDATORS_NUM,
            value: String(validatorsNum),
            name: 'numOfNodesDto.validatorsNum',
          },
          {
            label: Labels.REQUESTED_CPU,
            value: unitSplitter(validatorResources.cpuRequested),
            name: 'validatorResources.cpuRequested',
          },
          {
            label: Labels.CPU_LIMIT,
            value: unitSplitter(validatorResources.cpuLimit),
            name: 'validatorResources.cpuLimit',
          },
          {
            label: Labels.REQUESTED_MEMORY,
            value: unitSplitter(validatorResources.memoryRequested),
            name: 'validatorResources.memoryRequested',
          },
          {
            label: Labels.MEMORY_LIMIT,
            value: unitSplitter(validatorResources.memoryLimit),
            name: 'validatorResources.memoryLimit',
          },
          {
            label: Labels.STORAGE,
            value: unitSplitter(validatorResources.storageRequested),
            name: 'validatorResources.storageRequested',
          },
        ],
      },
      {
        title: 'Bootnode resources',
        fields: [
          {
            label: Labels.BOOTNODES_NUM,
            value: String(bootnodesNum),
            name: 'numOfNodesDto.bootnodesNum',
          },
          {
            label: Labels.REQUESTED_CPU,
            value: unitSplitter(bootNodeResources.cpuRequested),
            name: 'bootNodeResources.cpuRequested',
          },
          {
            label: Labels.CPU_LIMIT,
            value: unitSplitter(bootNodeResources.cpuLimit),
            name: 'bootNodeResources.cpuLimit',
          },
          {
            label: Labels.REQUESTED_MEMORY,
            value: unitSplitter(bootNodeResources.memoryRequested),
            name: 'bootNodeResources.memoryRequested',
          },
          {
            label: Labels.MEMORY_LIMIT,
            value: unitSplitter(bootNodeResources.memoryLimit),
            name: 'bootNodeResources.memoryLimit',
          },
          {
            label: Labels.STORAGE,
            value: unitSplitter(bootNodeResources.storageRequested),
            name: 'bootNodeResources.storageRequested',
          },
        ],
      },
    ],
  };
};
