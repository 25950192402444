/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountAllowDto } from './AccountAllowDto';
import type { AddressVotesDto } from './AddressVotesDto';
import type { GenesisConfigRequestDto } from './GenesisConfigRequestDto';
import type { NetworkNodeTypeStats } from './NetworkNodeTypeStats';
import type { NodesAllowDto } from './NodesAllowDto';
import type { ResourcesDto } from './ResourcesDto';
export type NetworkResponseDTO = {
  id?: string;
  name: string;
  validatorResources?: ResourcesDto;
  bootNodeResources?: ResourcesDto;
  genesisConfigDto?: GenesisConfigRequestDto;
  createdBy?: string;
  creationDate?: string;
  consensus?: string;
  state?: NetworkResponseDTO.state;
  nodeTypeStats?: Record<string, NetworkNodeTypeStats>;
  chainID?: string;
  addressVotes?: Array<AddressVotesDto>;
  accountsAllowListEnabled?: boolean;
  nodesAllowlistEnabled?: boolean;
  nodesAllowlist?: Array<NodesAllowDto>;
  accountsAllowlist?: Array<AccountAllowDto>;
};
export namespace NetworkResponseDTO {
  export enum state {
    INITIALIZING = 'INITIALIZING',
    INITSUCCEEDED = 'INITSUCCEEDED',
    INITFAILED = 'INITFAILED',
    QUORUMFAILED = 'QUORUMFAILED',
    QUORUMALIVE = 'QUORUMALIVE',
  }
}
